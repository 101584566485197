import React from "react"
import { graphql, Link, withPrefix } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import Helmet from "react-helmet"
import GridCoach from "../../components/gridcoach"
import "array-flat-polyfill"

const CoachCampsPage = ({ data }) => {
  /*
  let clean_pages = data.wagtail.page.filter((item, index) => {
    return item.__typename === "CoachPage"
  })
  */
  if (!data.wagtail.page || !data.wagtail.page.children) {
    return <></>
  }

  const cleanPages = [data.wagtail.page]
  let childrens = []
  childrens = cleanPages.map((item, index) => {
    return item?.children
  })
  childrens = childrens?.flat(1)

  const page = cleanPages[0]
  let _ancestor = { children: [], button: {} }
  page.ancestors.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    }
  })
  const pageCompliance = {
    __typename: "SimplePage",
    title: "Compliance",
    slug: "compliance",
    live: true,
  }
  _ancestor.children = _ancestor.children.filter((item) => {
    return item.__typename === "SimplePage"
  })

  const found = _ancestor.children.filter((item) => item.title === "Compliance")

  if (found.length === 0) {
    _ancestor.children.splice(2, 0, pageCompliance)
  }
const pageCourses = {
    __typename: "CoursesPage",
    title: "Courses",
    slug: "courses",
    live: true,
  }

  _ancestor.children.splice(2, 0, pageCourses)

  return (
    <>
      <Layout
        isContent={true}
        subMenu={_ancestor.children}
        button={_ancestor.button}
        parent={"coaches"}
        siteTitle={pageCompliance.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/coaches">Coaches</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="sport-safety-area">
          <div className="tab-content-area">
            <div className="container simple-page">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h1>{pageCompliance.title}</h1>
                      <p>{page.descriptionSimple}</p>
                    </div>
                  </div>{" "}
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="c-title-content"
                        dangerouslySetInnerHTML={{
                          __html: page.bodySafe,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GridCoach data={childrens} />

        {page.fullContent && page.fullContent.length > 0 && (
          <CTABlock data={page.fullContent[0]}></CTABlock>
        )}
      </Layout>
      <Helmet>
        <script src={withPrefix("scripts/custom.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}

export const query = graphql`
  {
    wagtail {
      page(id: 197312) {
        ... on CoachPage {
          title
          descriptionSimple
          bodySafe
          feedImage {
            src
            height
            width
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors(order: "-depth", limit: 1) {
            children {
              ... on SimplePage {
                title
                slug
                live
                isProtected
              }
              __typename
            }
          }
          children {
            title
            slug
            ... on CoachCompliancePage {
              lastname
              firstname
              club
              type
              exemptionDate
              coachtype
              isProtected
            }
            ancestors(order: "-depth", limit: 1) {
              title
              depth
            }
          }
          button {
            name
            text
            url
          }
        }
      }
    }
  }
`
export default CoachCampsPage
