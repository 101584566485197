import React, { useState, useCallback, useEffect } from "react"
import Moment from "moment"
import Loader from "react-loader-spinner"
import Select from "./Select/index"

const GridCoach = (props) => {
  const [filter, setFilter] = useState()
  const [data, setData] = useState(props.data)
  const [initialData] = useState(props.data)
  const [text, setText] = useState()
  const [loadMore, setLoadMore] = useState(20)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState("firstname")
  const [isRev, setIsRev] = useState(false)

  useEffect(() => {
    let _data = initialData
    _data = _data.filter(
      (item) =>
        (!text ||
          item.firstname.toLowerCase().includes(text.toLowerCase()) ||
          item.lastname.toLowerCase().includes(text.toLowerCase()) ||
          item.club.toLowerCase().includes(text.toLowerCase()) ||
          item.type.toLowerCase().includes(text.toLowerCase())) &&
        (!filter ||
          filter.length === 0 ||
          item.type.toLowerCase() === filter.toLowerCase())
    )
    _data.sort((a, b) => {
      const string1 = a[order] ? a[order].toLowerCase() : ""
      const string2 = b[order] ? b[order].toLowerCase() : ""
      return (string1 > string2) - (string1 < string2)
    })
    if (isRev) {
      _data.reverse()
    }
    setData(_data)
  }, [initialData, text, order, isRev, filter])

  const search = useCallback((event) => {
    setText(event.target.value)
  }, [])

  const onClick = useCallback(
    (event) => {
      const min = 0.5;
        const max = 1.8
      const rand = Math.floor(Math.random() * (max - min + 0.7) + min)
      setLoading(true)
      setTimeout(function () {
        setLoading(false)
        setLoadMore(loadMore + 20)
      }, rand * 1000)
    },
    [loadMore]
  )

  const orderBy = useCallback(
    (event) => {
      const type = event.target.dataset.order
      const isRevN = type === order && !isRev
      setOrder(order)
      setIsRev(isRevN)
    },
    [order, isRev]
  )

  const getLoadMore = useCallback(() => {
    const total = data ? data.length : 0
    if (total < 20) {
      return null
    }
    if (loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={onClick} className="button-load-more">
        Load More
      </button>
    )
  }, [onClick, loadMore, data])

  useEffect(() => {
    Moment.locale("en")
  }, [])

  const getRenderRow = useCallback(
    (item) => {
      const fields = ["firstname", "lastname", "club", "type", "exemptionDate"]
      const render = fields.map((sItem) => {
        const style = sItem === "type" ? { textTransform: "capitalize" } : {}
        if (order === sItem) {
          return (
            <td className="mw-20" style={style}>
              {item[sItem]}
            </td>
          )
        }
        return (
          <th className="mw-20" scope="row" style={style} key={`${sItem}-render-row`}>
            {item[sItem]}
          </th>
        )
      })
      return render
    },
    [order]
  )

  const handleChange = useCallback(({ name, value }) => {
    console.log(name)
    setFilter(value)
  })

  return (
    <section className="events-grid">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="select-items-area office">
              <div className="box">
                <p className="p-bold pb-10">Coach Type</p>
                <Select
                  name="filter"
                  value={filter}
                  handleChange={handleChange}
                  placeholder="All"
                  selectOptions={[
                    "all",
                    "returning",
                    "first year",
                    "exemption",
                  ]}
                />
              </div>
              <div className="box">
                <p className="p-bold pb-10" style={{ marginTop: "-10px" }}>
                  Search
                </p>
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="Enter keyword"
                    value={text}
                    onChange={search}
                  />
                  <button>
                    <img src={`../../images/search.png`} alt="src" />
                  </button>
                </div>
              </div>
            </div>
            <div className="table-area">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      onClick={orderBy}
                      data-order="firstname"
                      scope="col"
                      style={{ width: "25%" }}
                    >
                      First name
                      {order === "firstname" && (
                        <i
                          style={{
                            color: "#00AAD8",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className={`fas fa-arrow-${isRev ? "down" : "up"}`}
                        ></i>
                      )}
                    </th>
                    <th
                      onClick={orderBy}
                      data-order="lastname"
                      scope="col"
                      style={{ width: "25%" }}
                    >
                      Last name
                      {order === "lastname" && (
                        <i
                          style={{
                            color: "#00AAD8",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className={`fas fa-arrow-${isRev ? "down" : "up"}`}
                        ></i>
                      )}
                    </th>
                    <th
                      onClick={orderBy}
                      data-order="club"
                      scope="col"
                      style={{ width: "15%" }}
                    >
                      Club
                      {order === "club" && (
                        <i
                          style={{
                            color: "#00AAD8",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className={`fas fa-arrow-${isRev ? "down" : "up"}`}
                        ></i>
                      )}
                    </th>
                    <th
                      onClick={orderBy}
                      data-order="type"
                      scope="col"
                      style={{ width: "15%" }}
                    >
                      Type
                      {order === "type" && (
                        <i
                          style={{
                            color: "#00AAD8",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className={`fas fa-arrow-${isRev ? "down" : "up"}`}
                        ></i>
                      )}
                    </th>
                    <th
                      onClick={orderBy}
                      data-order="exemptionDate"
                      scope="col"
                      style={{ width: "20%" }}
                    >
                      Exemption Date{" "}
                      {order === "exemptionDate" && (
                        <i
                          style={{
                            color: "#00AAD8",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className={`fas fa-arrow-${isRev ? "down" : "up"}`}
                        ></i>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    if (index < loadMore) {
                      return (
                        <tr className="mw-100" scope="row" key={index}>
                          {getRenderRow(item)}
                        </tr>
                      )
                    }
                    return ""
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 align-items-center text-center">
            {loading && (
              <>
                <Loader type="Puff" color="#00aad8" height={100} width={100} />
                <br />
              </>
            )}
            {getLoadMore()}
          </div>
        </div>
      </div>
    </section>
  )
}

export default GridCoach
